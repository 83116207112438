import React from "react";
import { Link, withRouter } from "react-router-dom";
// import Fleets from "../../data-containers/fleets";
// import URLState from "../../data-containers/url-state";
import { useSelector } from "react-redux";
import { getFleet, getFleets } from "../../reducers/fleet";
// import { IFleet } from "../../reducers/fleet";

function FleetNavModal(props) {
  // const { includeAllSubfleets } = URLState.useContainer();
  const includeAllSubfleets = true;
  const { location, currentFleet, nestedFleets } = props;

  const getUrl = (fleetId) => {
    return location.pathname.replace(
      new RegExp(`^/${currentFleet.id}/(all/?)*`, "ig"),
      `/${fleetId}/`
    );
  };

  /* we are disabling the following line of code until we implement the v4 architecture fall 2022 */
  // const currentUrlSegment = [currentFleet.id].join("/");
  // if (includeAllSubfleets) {
  //   currentUrlSegment.push("all");
  // }

  let index = 0;
  const renderFleetLinks = (fleet) => {
    console.log("fleet", fleet);
    const { children = [] } = fleet;
    const hasChildren = !!children.length;
    const isCurb = fleet.id === "curb";

    const className = () => {
      const classes = [`fleet-item-${fleet.id}-${index}`];
      if(hasChildren){
        classes.push("parent")
      }
      if (fleet.id === currentFleet.id && !isCurb) {
        classes.push("active");
      }
      return classes.join(" ");
    };

    return (
      <li key={`${fleet.id}_${index++}`} className={className()}>
      {
        (isCurb
          ? <Link className={`${currentFleet.id === 'curb' ? "active" : ""}`} data-testid={`fleet-nav-${fleet.id}`} to={getUrl(`${fleet.id}/all`)}>{fleet.id} </Link>//<small>(and subfleets)</small>
          : <Link data-testid={`fleet-nav-${fleet.id}`}  to={getUrl(fleet.id)}>{fleet.id}</Link>)
      }
      { 
        hasChildren && <ul>
          {/* { isCurb && <li className={`${currentFleet.id === 'curb' && !includeAllSubfleets ? "active" : ""}`}>
            <Link data-testid={`fleet-nav-${fleet.id}`} to={getUrl(fleet.id)}>{fleet.id}</Link>
          </li>} */}
          {children.map((f) => renderFleetLinks(f))}
        </ul> 
      }
    </li>);
  };

  return <ul className="fleet-nav-list top-level">
    {nestedFleets.map((fleet) => renderFleetLinks(fleet))}
  </ul>;
}

// we are disabling the following line of code until we implement the v4 architecture fall 2022 
// FleetNavModal = withRouter(FleetNavModal);

const FleetNav = function (props) {
  /* we are disabling the following line of code until we implement the v4 architecture fall 2022 */
  // const { includeAllSubfleets } = URLState.useContainer();
  const currentFleet = useSelector(getFleet);
  const nestedFleets = useSelector(getFleets);
  // const { currentFleet, nestedFleets } = Fleets.useContainer();
  if (!currentFleet) {
    return <></>;
    // return null;
  }

  return (
    <nav>
      <FleetNavModal
        nestedFleets={nestedFleets}
        currentFleet={currentFleet}
        children={props.children}
        {...props}
      />
    </nav>
  );
};
//https://stackoverflow.com/a/57651878/1493796
// @ts-ignore
export default withRouter(FleetNav);
