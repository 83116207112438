import React, { useState, useEffect, useCallback, useReducer, useContext, useRef } from "react";
// import { InfiniteLoader, List, Table, Column } from "react-virtualized";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import LoadingSpinner from "../../../atoms/loading-spinner";
// import Explorer from "../../../../data-containers/explorer";
// import URLState from "../../../../data-containers/url-state";
// import DateRangeSelector from "./range-selector";
import LocationTable from "./table";
import LocationLabel from "./table-cell/label";
import LocationUsers from "./table-cell/users";
// import LocationTariff from "./table-cell/tariff";
import LocationHubs from "./table-cell/hubs";
// import LocationConsumption from "./table-cell/consumption";
// import LocationProduction from "./table-cell/production";
// import LocationDateModified from "./table-cell/date-modified";
// import LocationGridOffset from "./table-cell/offset";
import LocationFleet from "./table-cell/fleet";

// import ListLabel from './list/label';


import * as API from '../../../../lib/api';
import { useSelector } from "react-redux";
import { getFleet } from '../../../../reducers/fleet';
import { getPagination, paginationSize } from '../../../../reducers/dashboard';
import { AutoSizer } from "react-virtualized";

import DashboardContext from "../../context";

window._dashboard = {};
window._dashboard.loadedFleet = "";

const setLoadedFleet = (value) => {
  window._dashboard.loadedFleet = value;
}

window._dashboard.sTerm = "";
const setSTerm = (value) => {
  window._dashboard.sTerm = value;
}

// let skip = 0;
// const setSkip = (value) => { skip = value; }

const rowsReducer = (state, action) => {
  let newState = state;
  switch (action.type) {
    case 'update':
      newState = [...state];
      newState[action.index] = action.row;
      break;
    case 'append':
      newState = [...state];
      const newRows = action.rows.filter(({uuid})=>!newState.find(({uuid:u})=>u===uuid)).map((row)=>({...row, opened:false, height: 30}));
      newState = newState.concat(newRows);
      break;
    case 'reset':
      newState = [];
      break;
    default:
      throw new Error();
  }
  return newState;
}

const TABLE_WIDTH_SETTINGS = {
  default:[30,10,30,30],
  editFleet:[30,30,20,20],
}


export default function ActiveLocationsTable (props) {
  const [skip, setSkip] = useState(0);
  const { searchTerm } = useContext(DashboardContext);

  const rowHeights = useRef({});
  const listRef = useRef({});

  const getRowHeight = (index) => {
    return rowHeights.current[index] || 30;
  }

  const [localRows, dispatchRows] = useReducer(rowsReducer, []);
  const [moreLocationsToPage, setMoreLocationsToPage] = useState(false);
  const setAutoLoadNext = (value) => { 
    setMoreLocationsToPage(value); 
  };


  const setRowHeight = (index, size) => {
    if(localRows.length<=index) return;
    if(typeof listRef.current.resetAfterIndex === "function") listRef.current.resetAfterIndex(0);
    if(size===30) {
      if(localRows[index].opened) { return; }
      rowHeights.current = { ...rowHeights.current, [index]: size };
    }
    if(rowHeights.current[index]>size) return;
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }


  const refetchLocation = useCallback(async (locationId) => {
    try {
      const location = await API.getLocation(locationId);
      const ind = localRows.findIndex(({uuid})=>uuid===locationId);
      if(ind<0){
        dispatchRows({type:'append', rows:[location]});
      } else {
        dispatchRows({type:'update', index:ind, row:{...location, opened:true}});
      }
    } catch (err) {
      console.log(`${err.message}`);
    }
  });

  const [loadingNext, setLoadingNext] = useState(null);
  const fleet = useSelector(getFleet);
  const uuid = fleet ? fleet.uuid : "";

  useEffect( () => {
    if(window._dashboard.sTerm!==searchTerm){
      setSkip(0);
      setAutoLoadNext(false);
      setLoadingNext(false);
      setLoadedFleet("");    
      setSTerm(searchTerm);
    }
  }, [window._dashboard.sTerm, searchTerm]);

  let unfilledLocationsList = localRows.filter(({users})=>!Array.isArray(users));
  let unfilledLocationsLength = unfilledLocationsList.length;

  useEffect(()=> {
    unfilledLocationsList = localRows.filter(({users})=>!Array.isArray(users));
    unfilledLocationsLength = unfilledLocationsList.length;
  }, [unfilledLocationsLength])

  const loadLocationsFromServer = useCallback(async () => {
    const locId = unfilledLocationsList[0].uuid;
    if(locId) {
      try {
        const location = await API.getLocation(locId);
        const ind = localRows.findIndex(({uuid})=>uuid===locId);
        if(ind<0){
          dispatchRows({type:'append', rows:[location]});
        } else {
          dispatchRows({type:'update', index:ind, row:location});
        }
      } catch (err) {
        console.log(`${err.message}`);
      }
    }
  }, [unfilledLocationsLength]);

  useEffect( () => {
    if(unfilledLocationsLength>0) {
      loadLocationsFromServer();
    } 
  }, [unfilledLocationsLength]);

  // const { page, skip, sort } = useSelector(getPagination);
  const { page, sort } = useSelector(getPagination);

  // handles loading the list of locations from the server
  const loadLocationsListFromServer = () => { 
    if(uuid.length>10 && !moreLocationsToPage ) {
      try {
        if(searchTerm.length>0){
          API.searchFleetLocations({ fleet:uuid, page, skip, sort, searchTerm }).then((locations)=>{
            setSkip(skip+10);
            dispatchRows({type:'append', rows:locations})
            setMoreLocationsToPage(locations.length<paginationSize);
          }).catch((err)=>{
            console.log("Error", err);
          });
        } else {
          API.getFleetLocations({ fleet:uuid, page, skip, sort }).then((locations)=>{
            setSkip(skip+10);
            dispatchRows({type:'append', rows:locations})
            setMoreLocationsToPage(locations.length<paginationSize);
            console.log("[NEW STATE] Appending Locations -- what happens next?", locations);
          }).catch((err)=>{
            console.log("Error", err);
          });
        }
      } catch (err) {
        console.log(`${err.message}`);
      }
    }
  };

  const [selectedMapLocation, setSelectedMapLocation] = useState(Array.isArray(localRows) && localRows.length> 0 ? localRows[localRows.length-1]:undefined);

  const toggleExpand = ({rowIndex}) => {
    const row = localRows[rowIndex];
    if(row.opened) {
      dispatchRows({
        type:'update',
        index:rowIndex,
        row:{
          ...localRows[rowIndex], 
          height: 30}});
    }
    dispatchRows({
      type:'update',
      index:rowIndex,
      row:{
        ...row, 
        opened:!row.opened}});
  };

  const passiveLoadNext =  () => {
    if (loadingNext||moreLocationsToPage) {
      return;
    }
    setAutoLoadNext(true);
    setLoadingNext(true);
    loadLocationsListFromServer();
    setLoadingNext(false);
    setAutoLoadNext(false)
  };

  useEffect(() => {
    if(window._dashboard.loadedFleet!==uuid ) {
      setSkip(0);      
      setLoadedFleet(uuid);
    } 
  }, [window._dashboard.loadedFleet]); 
      


  function rowRenderer ({ key, index, style}) {
    const rowRef = useRef();
    const [cycleCount, setCycleCount] = useState(0);
    // row indexes where the fleet editor is open
    const [fleetEditorOpen, setFleetEditorOpen] = useState([]);
    const [widths, setWidths] = useState(TABLE_WIDTH_SETTINGS.default);
    const updateFleetEditorOpen = (values) => {
      setWidths(values.includes(index)?TABLE_WIDTH_SETTINGS.editFleet:TABLE_WIDTH_SETTINGS.default);
      setFleetEditorOpen(values);
    }

    useEffect(() => {
      if (rowRef.current) {
        const height = getRowHeight(index);
        if(height!==rowRef.current.clientHeight) {
          // setRowHeight(index, rowRef.current.clientHeight);
          setCycleCount(cycleCount+1);
        }
      }
      // eslint-disable-next-line
    }, [rowRef]);


    return (
      <div
        ref={rowRef}
        key={key}
        style={{ transition: "height 0.1s", borderBottom:"1px solid #c7c8ca", display:"flex", marginBottom: "-4px", marginTop:"4px", paddingTop:"4px" , ...style}}
      >
        <div style={{transition: "width 0.3s",width:`${widths[0]}%`}}>
          <LocationLabel
              adjustRowHeight={()=>{}}
              rows={localRows}
              rowIndex={index}
              {...props}
              setRowHeight={setRowHeight}
              toggleExpand={toggleExpand}
              showDownload={true}
              setSelectedMapLocation={setSelectedMapLocation}
              selectedMapLocation={selectedMapLocation}
            />
        </div>
        <div style={{transition: "width 0.3s",width:`${widths[1]}%`}}>
          <LocationFleet
            rows={localRows}
            {...props}
            adjustRowHeight={setRowHeight}
            rowIndex={index}
            updateFleetEditorOpen={updateFleetEditorOpen}
            fleetEditorOpen={fleetEditorOpen}
            />
          </div>
          <div style={{transition: "width 0.3s", width:`${widths[2]}%`}}>
            <LocationUsers
              setRowHeight={setRowHeight}
              rows={localRows}
              {...props}
              adjustRowHeight={()=>{}}
              rowIndex={index}
              refetchLocation={refetchLocation}
              />
            </div>
            <div style={{transition: "width 0.3s", width:`${widths[3]}%`}}>
            <LocationHubs
              setRowHeight={setRowHeight}
              rows={localRows}
              {...props}
              adjustRowHeight={()=>{}}
              rowIndex={index}
              />  
            </div>
      </div>
    )
  }



  const itemCount = (fleet && !fleet.uuid) || moreLocationsToPage ? localRows.length : localRows.length + 1;

  const isItemLoaded = index => !!localRows[index];
  

  return (<div className="explorer-locations explorer-locations-active">
    {/* {!dashboard.loading && <LoadingSpinner className="inline explorer-table-loading" />} */}
    {/* <DateRangeSelector /> */}
    
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      loadMoreItems={passiveLoadNext}
      itemCount={itemCount}
      threshold={1}
    >
      {({ onItemsRendered }) => (
      <AutoSizer>
      {({ height, width }) => {
        return (
        <> 
        <div style={{height:"15px",marginBottom:"10px", display:"flex", width:width+"px", flexDirection:"row", flexGrow:1, color: "#c7c8ca", fontSize: "small"}} className="ReactVirtualized__Table__headerRow location-row header-row">
          <div style={{width:"30%"}} className="column-header-left-align-label ReactVirtualized__Table__headerColumn">Address</div>
          <div style={{width:"10%"}} className="column-header-left-align-label ReactVirtualized__Table__headerColumn">Fleet</div>
          <div style={{width:"30%"}} className="column-header-left-align-label ReactVirtualized__Table__headerColumn">Users</div>
          <div style={{width:"30%"}} className="column-header-left-align-label ReactVirtualized__Table__headerColumn">Hubs</div>
        </div>
  
        
        <List
          height={height-25}
          onItemsRendered={onItemsRendered}
          ref={listRef}
          itemCount={itemCount}
          itemSize={getRowHeight}
          // rowRenderer={rowRenderer}
          width={width}
          style={{borderCollapse:"collapse"}}
        >
          {rowRenderer}
          
          </List></>)
          } }
        </AutoSizer>

        
      )}

    </InfiniteLoader>

    {loadingNext && (
      <div className="explorer-next-loading">
        <LoadingSpinner className="inline tiny" />
        &nbsp; Loading more rows&hellip;
      </div>
    )}
  </div>);

  }
