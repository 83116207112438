import { createSlice } from '@reduxjs/toolkit'

import { IHub } from './interfaces';


export interface IHubs {
    [key:string]: IHub
}

const hubs:IHubs = {};



export const hubsSlice = createSlice({
    name: 'hubs',
    initialState: {
      hubs
    },
    reducers: {
      setHub(state, action) {
          state.hubs[action.payload.uuid] = action.payload;
      }
    }
  })

  export const { setHub } = hubsSlice.actions;


export default hubsSlice.reducer

export const getHubs = (state:any):IHub[] => Object.values(state.hubs.hubs);
export const getHub = (state:any, id:string) => state.hubs.hubs[id];

