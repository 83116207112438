import { configureStore } from '@reduxjs/toolkit'

import fleetReducer from './fleet'
import userReducer from './user'
import locationsReducer from './locations';
import hubsReducer from './hubs';
import usersReducer from './users';
import dashboardReducer from './dashboard';

export default configureStore({
  reducer: {
    user: userReducer, // the User of the Web App
    fleet: fleetReducer, // list of fleets User of Web App has access to
    locations: locationsReducer, // list of all locations downloaded thus far
    hubs: hubsReducer, // list of all hubs downloaded thus far
    users: usersReducer, // list of all users downloaded thus far (for purpose of representing on the table)
    dashboards: dashboardReducer // dashbaord values like pagination, search terms; fleet to dasboards 1:1
  }
});



