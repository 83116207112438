import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from 'react-router-dom';
// import { addParamsToUrl, getQueryStringParams } from '../shared/utils/url';
// import auth, { callbackURL } from './lib/auth';
import { TokenManager } from './lib/api-utils';
// data containers
// import User from './data-containers/user';
// import URLState from './data-containers/url-state';
// import Fleets from './data-containers/fleets';
// components
import DashboardPage from './components/pages/dashboard';
import EnergyProjectionsPage from './components/pages/energy-projections';
import ErrorPage from './components/pages/error';
import NotFoundPage from './components/pages/not-found';
import LoadingSpinner from './components/atoms/loading-spinner';
// import EulaModal from './components/modals/eula';

import { useAuth0 } from "@auth0/auth0-react";

import { setFleet, setFleets, findFleet, setError, setLoading } from './reducers/fleet';
import { setUser, setError as setUserError, setLoading as setUserLoading } from './reducers/user';
import { addDashboard, existingDashboards, paginationSize } from './reducers/dashboard';
import * as API from './lib/api';
import filterFleets from './lib/fleetFiltering';


import { useSelector, useDispatch } from 'react-redux';


const defaultRoute = '/dashboard';

const indexRedirect = function(){
  return (<Redirect push={true} to={defaultRoute} />);
};

const STATE_OF_TOKEN = {
  VOID: "VOID", // nothing happened yet, first there was nothing
  GETTING: "GETTING", // getting the token
  SET: "SET", // have set the token
  ERROR: "ERROR" // error getting the token
}


const AppRouter = () => {
  const dispatch = useDispatch();
  const [tokenState, updateTokenState] = useState(STATE_OF_TOKEN.VOID);
  const [pause, setPause] = useState(true);
  const [initFleet, setInitFleet] = useState(null);
  
  const { user: authUser, isAuthenticated, isLoading, getAccessTokenSilently, logout, loginWithRedirect } = useAuth0();
  console.log(`[LOGIN] - isAuthenticated: ${isAuthenticated}, isLoading: ${isLoading}, ${typeof authUser}`);

  const handleLogout = function(){
    logout({ returnTo: window.location.origin });
    return null;
  }


  const authenticatedRoutes = function(props) {
    if(tokenState !== STATE_OF_TOKEN.SET){
      setPause(false);
      return null;
    }
    if(pause) {
      return null;
    }

    const MainContent = function(props){
      const { match, location } = props;
      const { dashboards = {} } = useSelector(state => state.dashboards);
      const { fleets, fleet={}, error:fleetLoadingError = false, loading: fleetLoading = true} = useSelector(state => state.fleet);
      const { user, error, loading } = useSelector(state => state.user);
      const { id:flId } = fleet;

      if(loading && fleetLoading&&!initFleet){
        setInitFleet(true);
        console.log('setting loading to be false');
        useEffect(() => {
          (async function getFleets() {
            try {            
              console.log('getting fleets');
              const fleets = await API.fetchUserFleets();
              console.log('fleets returned',fleets);
              if(fleets.length===0){
                dispatch(setFleets([
                  {
                    "id": "No Fleets",
                    "cobranding": "",
                    "uuid": "",
                    "children": []
                }
                ]));
                dispatch(setFleet(fleets[0]));
                setInitFleet(true);
                // setLoading
                dispatch(setLoading(false));
                dispatch(setError(false));  
              } else {
                const filteredFleets = filterFleets(fleets);
                dispatch(setFleets(filteredFleets));
                dispatch(setFleet(filteredFleets[0]));
                setInitFleet(true);
                // setLoading
                dispatch(setLoading(false));
                dispatch(setError(false));
              }
            } catch (err) {
              console.log('error getting fleets',err.message || err || 'Failed to retrieve fleets.');
              dispatch(setError(err.message || err || 'Failed to retrieve fleets.'));
              dispatch(setLoading(false));
            }
          })();
          (async function getUser() {
            try {
              const user = await API.fetchUser();
              dispatch(setUser(user));
              dispatch(setUserLoading(false));
              dispatch(setUserError(false));
            } catch (err) {
              console.log('error getting user',err.message || err || 'Failed to retrieve User.');
              dispatch(setError(err.message || err || 'Failed to retrieve User.'));
              dispatch(setLoading(false));
            }
          })();
        },[initFleet]);
      }

      let fleetId = match.url.replace(/\//g,"") || flId;
      // console.log(`Error ${error}; fleetLoadingError ${fleetLoadingError}; fleetLoading ${fleetLoading}; fleetId ${fleetId}; flId ${flId}    ${JSON.stringify(fleets)}`);
      if(error || fleetLoadingError){
        console.log(`Error ${error}; fleetLoadingError ${fleetLoadingError};`);
        const er = error || fleetLoadingError;
        return (
          <div>Error</div>
          // <ErrorPage {...er} />
        );
      }
      if(fleetLoading||loading){
        return (
          <div className="application-loader">
            <LoadingSpinner />
            <p className="application-loader-message">Loading application state</p>
            {loading&&<p className="application-loader-message">Loading user state</p>}
            {fleetLoading&&<p className="application-loader-message">Loading fleet state</p>}
          </div>   
        );

      } 

      if(!loading&&!fleetLoading&&(fleetId!==flId)){
        let defaultFleet = fleets.find(f => f.id === user.provider) || fleets[0];
        if(!fleetId){
          return <Redirect push={true} to={`/${defaultFleet.id}`} />
        }
        let matchingFleet = findFleet(fleets, fleetId);
        if(!matchingFleet){
          return (
            <Redirect push={true} to={`/${defaultFleet.id}`} />
          );
        } else {
          dispatch(setFleet(matchingFleet));
          if(!Object.keys(dashboards).includes(matchingFleet.id)) {
            dispatch(addDashboard({
              fleet: matchingFleet.id,
              page: paginationSize,
              skip: 0,
              sort: "_id",
              rows: []
            }));
          }
        }

      }

      return (isAuthenticated&& <Switch>
            <Route exact path={`${match.url}/energy-projections`} component={EnergyProjectionsPage} />
            {/* <Route exact path={`${match.url}/dashboard`} component={DashboardPage} /> */}
            <Route exact path={`${match.url}/dashboard`} component={DashboardPage} />

            {/* when navigating just to url */}
            <Route exact path={match.url} render={() => <Redirect to={`${match.url}${defaultRoute}`} />} />
            <Route exact path={`/no-fleets`} component={DashboardPage} />

            <Route exact path={`/dashboard`} component={DashboardPage} />
            <Route path="*" component={NotFoundPage} />

          </Switch>);
          
      //     ? <div className="application-loader">
      //     <LoadingSpinner />
      //     <p className="application-loader-message">Loading application state</p>
      //     {loading&&<p className="application-loader-message">Loading user state</p>}
      //     {fleetLoading&&<p className="application-loader-message">Loading fleet state</p>}
      //   </div>   
      // : (!userDetail.accepted_eula&&false) // skipping eula for now
      //   ? <EulaModal />
      //   :

    }

    return (
      // <div>
      //   <p>Test Route Fleet Commentd Out here.</p>
      // </div>
      // <Fleets.Provider>
      //   <URLState.Provider initialState={props}>
      //     <User.Provider initialState={ { loading: true, user: {}, fleets: [], userDetail: {} } }>
              <MainContent {...props} />
      //     </User.Provider>
      //   </URLState.Provider>
      // </Fleets.Provider>
    );
  }

  
  useEffect(() => {

    (async function login() {
      if (!isLoading && !authUser) {
          await loginWithRedirect({ redirectUri: window.location.origin });
      }
    })();

    const getData = async () => {
      if(isAuthenticated) {
        try {  
          updateTokenState(STATE_OF_TOKEN.GETTING);
          const token = await getAccessTokenSilently();
          TokenManager.setToken(token);
          TokenManager.setAuth0({getAccessTokenSilently: getAccessTokenSilently});
          console.log(`[LOGIN] - token: ${token}`);
          updateTokenState(STATE_OF_TOKEN.SET);
        } catch (e) {
            updateTokenState(STATE_OF_TOKEN.ERROR);
            console.log(`[LOGIN] - Error getting token ${e.message}`)
            // await loginWithRedirect({ redirectUri: window.location.origin });
            
           
          }

    }};
    getData();
    }, [authUser, isLoading]);
  

  if (isLoading) {
    console.log("[LOGIN] - Loading")
    return <LoadingSpinner />;
  }
  if (!isAuthenticated) {
    console.log("[LOGIN] - failing auth check")
    return <LoadingSpinner />;
  }
  return (<Router>
      <Switch>
        <Route path='/logout' render={handleLogout} />
        <Route exact path='/' render={indexRedirect} />
        <Route path="/:fleetId?/all" render={withRouter(authenticatedRoutes)} />
        <Route path="/:fleetId?" render={withRouter(authenticatedRoutes)} />
      </Switch>
    </Router>
  );
}

export default AppRouter;
