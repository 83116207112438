import React, { useEffect, useRef, useLayoutEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from "../../../../atoms/loading-spinner";
import ModalManager from "../../../../atoms/modal-manager";
import { IconLink } from "../../../../atoms/links";
// import CONFIG from "../../../../../lib/config";
// import LocationDetails from "../../../../../data-containers/location-details";
// import Fleets from "../../../../../data-containers/fleets";
import AddHubModal from "../../../../modals/add-hub";
import ErrorWithTooltip from "../../../../atoms/error-with-tooltip";
import DynamicCell from "../../../../atoms/table/dynamic-cell";
// import ImpersonationButton from "./impersonation-button";
// import HubConnectivityStatus from "../../../../hub/connectivity-status";
import { useDispatch, useSelector } from "react-redux";
// import { updateLocation, getLocations } from "../../../../../reducers/locations";
import { getDashboard, updateRow } from "../../../../../reducers/dashboard";

// import HubObject from "../../../../hub/hub-object";

// const EditHubLink = ({ installation, location, user }) => {
//   const { DEMO, DEMO_LOCATION_ID, CONNECT_URL } = CONFIG.values;

//   const impersonationLocationId = DEMO ? DEMO_LOCATION_ID : location.id;

//   const params = {
//     host: CONNECT_URL,
//     queryParams: {
//       entry: `/locations/${impersonationLocationId}/hubs/${installation.hub}`,
//     },
//   };

//   if (!user || !user.user_id) {
//     return null;
//   }

//   return (
//     <ImpersonationButton
//       userId={user.user_id}
//       loadingSpinnerClassName="inline full tiny"
//       params={params}
//     >
//       <span className="linked-icon edit-hub-link">
//         <FontAwesomeIcon icon="pencil-alt" />
//       </span>
//     </ImpersonationButton>
//   );
// };

/* TODO make a separate installation component with its own effect hook to load hub and hubconnectivity */

function HubsContent(props) {
  
  // const { getDetail, getImpersonationUser, fetchHubsExtended } = LocationDetails.useContainer();
  // const getImpersonationUser = () => void(0);

  const hubapp = process.env.REACT_APP_HUBAPP_URL;
  const { rowIndex,rows,setRowHeight } = props;
  const detail = rows[rowIndex]||{};
  const hubRef = useRef(null);

  // const locations = useSelector(getLocations);
  // const detail = locations[id];
  // const { fleetPermissions } = Fleets.useContainer();
  // const { location_can_edit_hub, location_can_add_hub } = fleetPermissions;
  const location_can_add_hub = false;
  
  const { rowData = { get: null }, omitAddToActive } = props;
  const { opened=false } = detail;
  // const { id, opened:expanded } = rowData;

  // const detail = getDetail(id) || {};
  const { hubs = [], users } = detail;
  // users should be undefined if not loaded yet
  let hubsLoading = true;
  if(users && Array.isArray(users)){ hubsLoading = false; }


  useLayoutEffect(() => {
    if(hubRef.current) {
      if(opened) {
        console.log("HUBS HEIGHT SETTING",hubRef.current.clientHeight);
        setRowHeight(rowIndex, hubRef.current.clientHeight);
        // adjustRowHeight();
      }
    }
    if(!opened) {
      setRowHeight(rowIndex, 30);
    }
  }, [hubRef]);

  if (hubsLoading) {
    return <div style={{width:"100%"}}></div>
    return <LoadingSpinner className="inline" />;
  }
  if (!hubs.length) {
    return (<div className="no-results">No hubs found</div>);
  }

  const addHubModalProps = {
    className: "add-hub-modal",
    render: (modalProps) => <AddHubModal {...modalProps} location={rowData} />,
  };

  // const impersonationUser = getImpersonationUser(id);

  return (
    <React.Fragment>
      <div ref={hubRef} className="explorer-list installation-list list">
        {!opened && hubs.length>1 && (
         <div className="serial-number">{hubs.length} hubs</div>
        )}
        {!opened && hubs.length===1 && (
           <div className="serial-number">{hubs[0].id}</div>
        )}
        {opened && hubs.map((hub, index) => {
          if (
            hub.hubConnectivity &&
            hub.hubConnectivity.error &&
            hub.hubConnectivity.error.status === 403
          ) {
            return <span>403</span>;
          }
          return (
            <div
              className="explorer-item installation-item list-item"
              key={hub.id}
            >
              <div className="hub-status">
                {/* connectivity */}
                {/* <HubConnectivityStatus id={id} installation={hub} /> */}
              </div>

              {opened && (
                <React.Fragment>
                  <div className="row-expanded-section light hubslist">
                    {hub.loading && <LoadingSpinner className="inline tiny" />}
                    {/* {hub.error && (
                      <ErrorWithTooltip
                        className="installation-error"
                        error={hub.error}
                      />
                    )} */}
                    {/* <HubObject id={id} installation={inst} /> */}
                  </div>
                  {/* serial */}
                  <div className="serial-number"><span style={{display:"inline-block", width:"65px"}}>{hub.id}</span>
                    <small>
                      <a 
                        href={`${hubapp}?hid=${hub.id}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        data-testid={`open-hub-dashboard-${hub.id}`}>
                        <button style={{minWidth:0, fontSize: "6px",padding: "4px", margin:"-4px 0 0 14px"}}>
                          edit
                        </button>
                      </a>
                    </small>
                  </div>
                </React.Fragment>
              )}

              {/* {!opened && (<div className="serial-number">{hub.id}</div>)} */}
 
              {/* {location_can_add_hub && expanded && (
                <ModalManager
                  modalProps={addHubModalProps}
                  render={() => (
                    <IconLink
                      icon="plus"
                      className="add-user"
                      style={{ padding: ".5rem 0rem" }}
                    >
                      Add Hub
                    </IconLink>
                  )}
                />
              )} */}
              {index+1 === hubs.length && (<br />)}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

function Wrapper(props) {
  return (
    <div className="location-row-hubs">
      <HubsContent {...props} />
    </div>
  );
}

export default Wrapper;
// export default DynamicCell(Wrapper);
